<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Modal Awal Toko</p>
     <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datamodal.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
           <tr>        
              <td class="capitalize">modal</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="text" id="modal" name="modal" class="form-control form-control-sm" placeholder="modal" v-model="datamodal.modal" >
                  </div>
              </td>
          </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary disabled'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <!-- <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datamodal = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button> -->
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class=" col-sm-7 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length>0" class="p-2">
                <p class="text-red-400 text-sm">Modal Akhir = ( modal + penambahan dana ) - pengeluaran dana)</p>
                <p class="text-red-400 text-sm">Untuk melihat hasil perhitungan modal dan pengurangan dengan transaksi lihat di bagian report!</p>
                <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','harga','stok','keluar']" @selected="ambil" ref="btable" />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datamodal: {},
      produks:[],
      btn: "update",
      stok:0,
      datanya:[],
    };
  },
  methods: {
    format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
    async getData(){
    let penambahan = await sdb.collection("app_kasir_toko_penambahan").doc().get("*","order by id");
    let pengeluaran = await sdb.collection("app_kasir_toko_pengeluaran").doc().get("*","order by id");
    penambahan=penambahan.reduce((e,n)=>{
        return e+parseInt(n.jumlah)
    },0)
    pengeluaran=pengeluaran.reduce((e,n)=>{
        return e+parseInt(n.jumlah)
    },0)
    sdb.collection("app_kasir_toko_modal",false).doc().select(`select * from app_kasir_toko_modal`).then(res => {
        this.datanya=res;
        this.datamodal=res[0]
        this.datamodal.modal_akhir=(parseInt(res[0].modal)+penambahan)-pengeluaran
        this.$forceUpdate();
      });
    },
    ambil(data) {
      this.datamodal = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    submit() {
      let that=this
      if(this.datamodal.id_produk==''){
        alert('Barcode Produk terlebih dahulu')
          return
      }
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datamodal
          delete this.datamodal.id;
          sdb
            .collection("app_kasir_toko_modal")
            .doc()
            .set(this.datamodal)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_toko_modal")
            .doc()
            .set(this.datamodal)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_toko_modal")
            .doc(this.datamodal.id)
            .delete()
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
    // this.datamodal.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")

  },
};
</script>